import { graphql, Link, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import tw, { styled } from 'twin.macro';

import Facebook from '../assets/icons/fb.svg';
import Houzz from '../assets/icons/houzz.svg';
import Instagram from '../assets/icons/ig.svg';
import Pinterest from '../assets/icons/pinterest.svg';
import { IS_RELEASED } from '../constants/env';
import { Decorator } from '../core-ui/Decorator';
import { RichText, Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { FooterSettingsQuery } from '../__generated__/types';
import { Button } from './Button';

const FooterContainer = styled.footer(() => [
  tw`bg-black pt-28 pb-70 md:pt-70`,
  //   IS_RELEASED && 'margin-top: 230px;',
]);
const FloatingInfoContainer = styled.div`
  top: 0;
  margin-top: -67%;
  -webkit-box-shadow: -10px -5px 105px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: -10px -5px 105px 0px rgba(0, 0, 0, 0.16);
  box-shadow: -10px -5px 105px 0px rgba(0, 0, 0, 0.16);
  ${tw`flex flex-col lg:flex-row bg-gray-100 px-0 relative mb-60`}
  @media only screen and (min-width: 768px) {
    margin-top: -300px;
  }
`;
const Container = styled(Wrapper)`
  ${tw`justify-between flex flex-col xl:flex-row`}
`;
const Row = tw.div`flex`;
const IconSpacing = tw`mr-16 md:mr-24`;
const Icon = tw`h-16 md:h-24`;
const FloatingInfoColumn = tw.div`flex lg:w-1/2 flex-col`;

type Props = {
  data: FooterSettingsQuery;
};
export const BaseFooter: React.FC<Props> = ({ data }) => {
  const footerData = data.allPrismicSiteSettings.edges?.[0]?.node.data;
  const imageFluid = footerData?.illustration?.fluid;
  return (
    <FooterContainer>
      {IS_RELEASED ? (
        <Wrapper>
          <FloatingInfoContainer>
            <FloatingInfoColumn
              css={tw`px-16 pb-16 pt-36 md:px-32 md:pb-32 lg:p-64`}
            >
              <Decorator css={tw`mb-16 sm:mb-40`} />
              <Text css={tw`pb-8 md:pb-16 `} type={TextType.caption}>
                CONTACT US
              </Text>
              <Text css={tw`pb-24`} type={TextType.subtitle}>
                {footerData?.footer_title?.text}
              </Text>
              <Link to="/contact">
                <Button
                  label={footerData?.button_label ?? `Let's Chat`}
                  css={tw`w-auto`}
                />
              </Link>
            </FloatingInfoColumn>
            <FloatingInfoColumn>
              {imageFluid ? (
                <Image fluid={imageFluid} css={tw`w-full h-full`} />
              ) : (
                <img
                  src={footerData?.illustration?.url || undefined}
                  css={[tw`w-full h-full`]}
                />
              )}
            </FloatingInfoColumn>
          </FloatingInfoContainer>
        </Wrapper>
      ) : null}
      <Container>
        <Text
          type={TextType.caption}
          css={[
            tw`text-white self-center order-last xl:order-first text-center xl:text-left xl:flex leading-22 lg:leading-19`,
          ]}
        >
          <pre>
            ©{footerData?.site_title?.toUpperCase() || 'SCANDI DESIGN'}.{' '}
          </pre>
          <pre>
            <Text type={TextType.caption}>ALL RIGHTS RESERVED.</Text>
          </pre>
        </Text>
        <div
          css={tw`items-center order-first xl:order-last flex flex-col xl:flex-row`}
        >
          {footerData?.phone_number ? (
            <Row css={tw`pb-24 xl:pb-0`}>
              <Text
                type={TextType.caption}
                css={[tw`text-white text-opacity-40 pr-16 `]}
              >
                PHONE
              </Text>
              <a href={`tel:${footerData?.phone_number}`}>
                <Text type={TextType.caption} css={[tw`text-white`]}>
                  {footerData?.phone_number}
                </Text>
              </a>
            </Row>
          ) : null}
          {IS_RELEASED ? (
            <>
              <div css={[tw`h-full w-px bg-darkGrey mx-24 tracking-widest `]} />
              <Row css={tw`items-center pb-36 xl:pb-0`}>
                <Text
                  type={TextType.caption}
                  css={[tw`text-white text-opacity-40 pr-16 tracking-widest`]}
                >
                  FOLLOW US ON
                </Text>

                {footerData?.facebook_url?.url ? (
                  <IconButton
                    css={IconSpacing}
                    href={footerData?.facebook_url?.url}
                  >
                    <Facebook css={Icon} />
                  </IconButton>
                ) : null}
                {footerData?.instagram_url?.url ? (
                  <IconButton
                    css={IconSpacing}
                    href={footerData?.instagram_url?.url}
                  >
                    <Instagram css={Icon} />
                  </IconButton>
                ) : null}
                {footerData?.houzz_url?.url ? (
                  <IconButton
                    css={IconSpacing}
                    href={footerData?.houzz_url?.url}
                  >
                    <Houzz css={Icon} />
                  </IconButton>
                ) : null}
                {footerData?.pinterest_url?.url ? (
                  <IconButton
                    css={IconSpacing}
                    href={footerData?.pinterest_url?.url}
                  >
                    <Pinterest css={Icon} />
                  </IconButton>
                ) : null}
              </Row>
            </>
          ) : null}
        </div>
      </Container>
    </FooterContainer>
  );
};

const renderFooter = (data: FooterSettingsQuery) => <BaseFooter data={data} />;

export const Footer: React.FC = () => {
  return (
    <StaticQuery<FooterSettingsQuery>
      query={graphql`
        query FooterSettings {
          allPrismicSiteSettings {
            edges {
              node {
                data {
                  site_title
                  button_label
                  facebook_url {
                    link_type
                    target
                    url
                  }
                  footer_title {
                    html
                    richText
                    text
                  }
                  houzz_url {
                    link_type
                    target
                    url
                  }
                  instagram_url {
                    link_type
                    target
                    url
                  }
                  pinterest_url {
                    link_type
                    target
                    url
                  }
                  phone_number
                  illustration {
                    url
                    fluid(maxHeight: 413, maxWidth: 750) {
                      ...GatsbyImgixFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={renderFooter}
    />
  );
};

const IconButtonWrapper = styled.a`
  ${tw`flex flex-col relative`}
  &:after {
    position: absolute;
    bottom: -8px;
    background-color: #ffffff;
    content: '';
    height: 2px;
    width: 0px;
    ${tw`transition-all duration-100 ease-in-out`}
  }
  &:hover {
    &:after {
      ${tw`lg:w-full`}
    }
  }
`;

const IconButton: React.FC<{ href?: string }> = ({ ...props }) => {
  return (
    <IconButtonWrapper {...props} target="_blank" rel="noopener noreferrer" />
  );
};
